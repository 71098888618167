import React from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import "./VerifyOTP.scss";
import Image from "../../assets/Automated_Pricing_Calc.jpg";
import { SendOTPAPI, VerifyOtpAPI } from '../../Constants/Api/Api';

const VerifyOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const userotp = formData.get("userotp");

    try {
      const res = await VerifyOtpAPI(email, userotp);
      alert(res?.data?.message); // Replace with toast notification if needed
      navigate("/sign-up", { state: { email } });
    } catch (error) {
      alert(error?.response?.data?.message || "Please Try Again");
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = await SendOTPAPI(email);
      alert(res?.data?.message); // Replace with toast notification if needed
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Container className="VerifyOtp main-container">
      <div className="Main">
        <div className="Left">
          <img src={Image} width="100%" alt="logo" />
        </div>
        <div className="Right">
          <div className="form_inner">
            <h6>OTP has been sent to your <strong>{email}</strong></h6>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formOTP">
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  type="text"
                  name="userotp"
                  placeholder="Enter OTP"
                  required
                />
              </Form.Group>
              <p className="forgotpasswordtext" onClick={handleResendOtp} style={{ cursor: "pointer" }}>
                Resend OTP
              </p>
              <div className="d-grid">
                <Button type="submit" variant="primary" className="button_theme">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VerifyOTP;
