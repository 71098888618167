import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap'
import "./AdvancedPrice.scss"
import { useLocation, useNavigate } from 'react-router-dom';
function AssessmentPrograms({ handleCloseBH }) {
    const firstSelectValue = 'AssessmentPrograms';
    const [secondSelectValue, setSecondSelectValue] = useState('');
    const [showParticipants, setShowParticipants] = useState(false);
    const [participantsValue, setParticipantsValue] = useState('');
    const [aupValue, setAduValue] = useState('');
    const [customParticipants, setCustomParticipants] = useState('');
    const [customAdu, setCustomAdu] = useState();
    const [grossamount, setGrossAmount] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [assessmentfee, setAssessmentFee] = useState('');
    const [dataStored, setDataStored] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        text: "",
        icon: "",
    });
    const navigate = useNavigate()
    const location = useLocation()
    let slug = new URLSearchParams(location?.search).get("s");
    const levelsData = {
        'AssessmentPrograms': {
            'DISC & Emotional Intelligence Combo Assessment(1-200)': { participants: '1-200', aup: '$85-$149' },
            'DISC & Emotional Intelligence Combo Assessment(201-299)': { participants: '201-299', aup: '$75-$84.99' },
            'DISC & Emotional Intelligence Combo Assessment(300+)': { participants: '300+', aup: '$65-$74.99' },
            'Leadership 360 Assessment': { participants: '1-100', aup: '$200-$500' },
            'Group Executive Report': { participants: '1-100', aup: '$100-$500' },
            'Sales Assessment': { participants: '1-100', aup: '$85-$149' },
            'Sales Leader Assessment': { participants: '1-100', aup: '$200-$500' },
            'Organizational Assessment': { participants: '25-1000', aup: '$500-$1500' },
        },
    };
    const handleSecondSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (firstSelectValue && levelsData[firstSelectValue] && levelsData[firstSelectValue][selectedValue]) {
            setSecondSelectValue(selectedValue);
            const selectedLevel = levelsData[firstSelectValue][selectedValue];
            setParticipantsValue(selectedLevel.participants);
            setAduValue(selectedLevel.aup);
            setShowParticipants(true);
        } else {
            setShowParticipants(false);
            setParticipantsValue('');
            setAduValue('');
        }
    };
    const handleCustomParticipantsChange = (e) => {
        const value = e.target.value;
        setCustomParticipants(value);
        calculateResult(value, customAdu);
    };
    const handleCustomAduChange = (e) => {
        const value = e.target.value;
        setCustomAdu(value);
        calculateResult(customParticipants, value);
    };
    const handleDiscountChange = (e) => {
        const value = e.target.value;
        setDiscountValue(value);
        calculateDiscount(value);
    };
    const calculateResult = (participants, aup) => {
        if (participants && aup) {
            const result = parseInt(participants) * parseFloat(aup);
            setGrossAmount(result);
            calculateDiscount(discountValue);
        } else {
            setGrossAmount('');
            setDiscountAmount('');
        }
    };
    const calculateDiscount = (discount) => {
        if (grossamount && discount) {
            const discountAmount = (grossamount * discount)
            setDiscountAmount(discountAmount);
        } else {
            setDiscountAmount('');
        }
    };
    const handleStoreData = () => {
        // const data = {
        //     heading: "Assessment Programs",
        //     Custom_Participants: customParticipants,
        //     Custom_Adu: customAdu,
        //     Gross_Amount: grossamount,
        //     Assessment_fee: assessmentfee,
        //     Net_Amount: (grossamount - customParticipants * assessmentfee).toFixed(2),
        //     Discount_Amount: discountAmount,
        //     After_Discount: ((grossamount - customParticipants * assessmentfee) - discountAmount).toFixed(2),
        //
        // };
     
        const data = {
            heading: "Assessment Programs",
            Custom_Participants: customParticipants,
            Custom_Adu: customAdu,
            Net_Amount: (grossamount - customParticipants * assessmentfee).toFixed(2),
        };
        if (discountValue !== "no") {
            data.Discount_Amount = discountAmount;
            data.After_Discount = ((grossamount - customParticipants * assessmentfee) - discountAmount).toFixed(2)
        }
        data.Gross_Amount = grossamount;
        data.Assessment_fee = assessmentfee;
        localStorage.removeItem("Calculation");
        localStorage.setItem('Calculation', JSON.stringify(data));
        // handleCloseBH()
        setAlertConfig({
            text: "Data Stored For Proposal.",
            icon: "success",
        });
        setAlert(true);
        setTimeout(() => {
            if (!slug) {
                navigate("/create/leadership-workshop-proposal");
            }
        }, 2000);
    }
    return (
        <div className="AdvancedPrice">
            <div className='Calculator_container'>
                <div className='Row_1'>
                    <div className='Col_1' style={{ padding: "4px" }}  >
                        <div className='blank_input' style={{ width: "inherit", padding: "7px" }}>Assessment Programs </div>
                    </div>
                    <div className='Col_1'>
                        <Form.Select value={secondSelectValue} onChange={handleSecondSelectChange} >
                            <option value=""> select--</option>
                            {levelsData[firstSelectValue]
                                ? Object.keys(levelsData[firstSelectValue]).map((level, index) => (
                                    <>
                                        <option key={index} value={level}>
                                            {level}
                                        </option>
                                    </>
                                ))
                                : null}
                        </Form.Select>
                    </div>
                </div>
                {showParticipants && (<>
                    {/* <div className='blank_input'>Participants: {participantsValue}</div> */}
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                            <div className='Col_12'>
                                <div className='blank_input'>{participantsValue}</div>
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Participants'
                                    value={customParticipants}
                                    onChange={handleCustomParticipantsChange} /></div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Per Assessment Fee Range:
                            <div className='Col_12'>
                                <div className='blank_input'>{aupValue}</div>
                            </div>
                        </div>
                    </div>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Input Fee you want to charge
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Aup'
                                    value={customAdu}
                                    onChange={handleCustomAduChange} /></div>
                        </div>
                    </div>
                </>
                )
                }
                {customParticipants &&grossamount&&
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Gross Amount :
                            <div className='Col_12 blank_input'>${grossamount}
                            </div>
                        </div>
                    </div>
                }
                {customParticipants &&
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>Assessment Fee
                            <div className='Col_12'>
                                <Form.Control placeholder='Enter Participants'
                                    value={assessmentfee}
                                    onChange={(e) => {
                                        setAssessmentFee(e.target.value)
                                    }} /></div>
                        </div>
                    </div>
                }
                {assessmentfee && (<>
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Net Amount :
                            <div className='Col_12 blank_input'>${(grossamount - customParticipants * assessmentfee).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </>
                )}
                {showParticipants &&
                    <Form.Select value={discountValue} onChange={handleDiscountChange} style={{ padding: "9px" }}>
                        <option value="">Select discount</option>
                        <option value="no">No discount</option>
                        <option value={0.02}>2%</option>
                        <option value={0.05}>5%</option>
                        <option value={0.1}>10%</option>
                        <option value={0.12}>12%</option>
                        <option value={0.15}>15%</option>
                        <option value={0.20}>20%</option>
                        <option value={0.25}>25%</option>
                        <option value={0.30}>30%</option>
                    </Form.Select>}
                {discountValue !== "no" &&discountAmount&& (
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Discount Amount (Gross Amount * Discount %) :
                            <div className='Col_12 blank_input'>${discountAmount}
                            </div>
                        </div>
                    </div>
                )}
                {discountValue !== "no" && discountAmount&&(
                    <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                            Price after Discount (Net Amount - Discount Amount) :
                            <div className='Col_12 blank_input'>${((grossamount - customParticipants * assessmentfee) - discountAmount).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Button className="button_theme"
                onClick={() => { handleStoreData(); handleCloseBH() }}>Add to proposal
            </Button>
        </div>
    );
}
export default AssessmentPrograms;
