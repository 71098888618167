import React, { useEffect, useRef, useState } from 'react'
import "./Proposal.scss"
import "./LeadershipProposal.scss"
import "../../Components/Calculator/AdvancedPrice/AdvancedPrice.scss"
import { Button, Modal, Row } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print'
import { Editor } from '@tinymce/tinymce-react';
import { DraftCalculationApi, ProposalViewApi, UpdateDraft } from '../../Constants/Api/Api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Signin from '../../Pages/Signin/Signin'
import AdvancedPrice from '../Calculator/AdvancedPrice/AdvancedPrice'
import { message } from 'antd'
const LeadershipProposal = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [values, setValues] = useState()
  const contentRef = useRef(null);
  const location = useLocation()
  // const usedata = location?.state
  const [usedata, setUseData] = useState(location?.state)
  // console.log(usedata, "usedatausedata")
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [])
  const [initialContent1, setInitialContent1] = useState(usedata?.proposal_data?.section_1 ? usedata?.proposal_data?.section_1 : `<h2 style="text-align: center;"><span style="color: rgb(9, 23, 153);"><strong>EXECUTIVE SUMMARY</strong></span></h2>
  <h3><strong><span style="color: rgb(9, 23, 153);">SUBHEAD (optional)</span></strong></h3>
  <p>Summarize the training to be provided, briefly reiterate why you are the best person to provide this training and explain how the company will benefit from the training.</p>
  <p>For example:</p>
  <p>We are pleased to present ABC Inc. with a proposal to train 25 managers. We will bring 25 years of Leadership training plus resources from the #1 leadership guru in the world. We will lead your staff through an in depth study of XXX. As a result of this training your company will experience increased productivity&hellip;</p>`)
  const [content1, setContent1] = useState(initialContent1);
  const [lgShow, setLgShow] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const handleClose = () => {
    if (unsavedChanges && window.confirm('You have unsaved changes. Do you want to discard them?')) {
      handleCloseModal();
      return;
    }
    if (unsavedChanges) {
      return;
    }
    handleCloseModal();
  };
  const handleCloseModal = () => {
    setLgShow(false);
    setContent1(initialContent1);
    setUnsavedChanges(false);
  };
  const handleSave = () => {
    setInitialContent1(content1);
    setUnsavedChanges(false);
    setLgShow(false);
  };
  const [initialContent2, setInitialContent2] = useState(usedata?.proposal_data?.section_2 ? usedata?.proposal_data?.section_2 : `<h3><strong><span style="color: rgb(9, 23, 153);">OBJECTIVES</span></strong></h3>
  <ol>
  <li>Objectives should be a list of what you and the company had previously identified as important concepts that all attendees should grasp through the training.</li>
  <li>Participants will be able to recognize&hellip;</li>
  <li>Each team member will&hellip;</li>
  <li>Etc, etc, etc</li>
  </ol>
  <h3><strong><span style="color: rgb(9, 23, 153);">TRAINING SCHEDULE</span></strong></h3>
  <p>Provide a detailed schedule of the training to be provided.</p>
  <p>For example&hellip;</p>
  <p>April 1, 2020 - Session 1:&nbsp;<strong>Leadership is Influence</strong></p>
  <p>April 8, 2020 - Session 2:&nbsp;<strong>Leadership is Influence</strong></p>
  <h3><span style="color: rgb(9, 23, 153);"><strong>RESOURCES</strong></span></h3>
  <ul>
  <li>List any resources that you will be providing.</li>
  <li>- Books</li>
  <li>- Binder for handouts and notes</li>
  <li>- Handouts</li>
  <li>- etc.</li>
  </ul>`)
  const [content2, setContent2] = useState(initialContent2);
  const [lgShow2, setLgShow2] = useState(false);
  const [unsavedChanges2, setUnsavedChanges2] = useState(false);
  const handleClose2 = () => {
    if (unsavedChanges2 && window.confirm('You have unsaved changes. Do you want to discard them?')) {
      handleCloseModal2();
      return;
    }
    if (unsavedChanges2) {
      return;
    }
    handleCloseModal2();
  };
  const handleCloseModal2 = () => {
    setLgShow2(false);
    setContent2(initialContent2);
    setUnsavedChanges2(false);
  };
  const handleSave2 = () => {
    setInitialContent2(content2);
    setUnsavedChanges2(false);
    setLgShow2(false);
  };
  const [initialContent3, setInitialContent3] = useState(usedata?.proposal_data?.section_3 ? usedata?.proposal_data?.section_3 : `<h4 style="text-align: center;">Workshop Agreement</h4>
  <p>This agreement is between your &nbsp;&nbsp;<span contenteditable="true">Name</span>&nbsp;, &nbsp;&nbsp;<span contenteditable="true">Company Name</span>&nbsp;&nbsp; and &nbsp;&nbsp;<span contenteditable="true">Address</span>&nbsp;&nbsp;</p>
  <p>&nbsp;&nbsp;<span contenteditable="true">Company Name</span>&nbsp;&nbsp;wishes to retain the services of ,&nbsp;&nbsp;<span contenteditable="true">Name</span>&nbsp;&nbsp;to deliver training for &nbsp;&nbsp;<span contenteditable="true">_________________</span>&nbsp;&nbsp;participants.</p>
  <p><strong>Date:&nbsp;</strong>&nbsp;&nbsp;<span contenteditable="true">_________________</span></p>
  <p><strong>Program Time:</strong>&nbsp;&nbsp;&nbsp;<span contenteditable="true">_________________</span></p>
  <p><strong>Program Location:</strong>&nbsp;&nbsp;&nbsp;<span contenteditable="true">_________________</span></p>
  <p><strong>Program Title:&nbsp;</strong>&nbsp;&nbsp;<span contenteditable="true">_________________</span></p>
  <p><strong>Number of Participants:&nbsp;</strong>&nbsp;&nbsp;<span contenteditable="true">_________________</span></p>
  <p>Provide understanding of agreement: Full Service Solutions agrees to present the information and material contained in the program described above. Full Service Solutions also agrees to coordinate the details of this program with Jane Doe (identify Point of Contact) in order to achieve the outcomes that ABC Inc has stated. ABC Inc agrees to provide the room setup and audiovisual equipment described below.</p>
  <ul>
  <li>Program Logistics (list specific requirements &ndash; things to consider are listed)</li>
  <li>When do you need access to the room?</li>
  <li>How should the room be set up?</li>
  <li>What equipment needs to be available and/or setup?</li>
  <li>Will you need technical support available?</li>
  <li>What refreshments are you expecting and who will provide them?</li>
  </ul>
  <p>In exchange for the products and services provided, ABC Inc agrees to compensate Full Service Solutions as follows:</p>
  <p><strong>Professional Fee:</strong>&nbsp;&nbsp;<span contenteditable="true">Amount</span>&nbsp;&nbsp;</p>
  <p><strong>Deposit:</strong>Describe how much and when Deposit is due &ndash; When contract is signed?</p>
  <p><strong>Balance:</strong>Describe how much and when balance is due &ndash; At completion of training?</p>
  <p>You may want to add here what happens if training is canceled for any reason. Is there a full refund? Is the refund prorated based on when canceled prior to training (ie. 30 days out)? Is the client responsible to pay costs of books, Binders, etc.? How should they notify you of cancelation?</p>
  <ul>
  <li>Other considerations:</li>
  <li style="list-style-type: none;">- Can the client record your training?</li>
  <li style="list-style-type: none;">- Can the client substitute participants?</li>
  </ul>
  <p>This constitutes the entire agreement between both parties.</p>
  <div>
  <div>
  <p>&nbsp;&nbsp;<span contenteditable="true">___Name___</span>&nbsp; &nbsp;&nbsp;<span contenteditable="true">___Date___</span>&nbsp;</p>
  </div>
  <div>
  <p>&nbsp;&nbsp;<span contenteditable="true">___Name___</span>&nbsp; &nbsp;&nbsp;<span contenteditable="true">___Date___</span>&nbsp;</p>
  </div>
  </div>`)
  const [content3, setContent3] = useState(initialContent3);
  const [lgShow3, setLgShow3] = useState(false);
  const [unsavedChanges3, setUnsavedChanges3] = useState(false);
  const handleClose3 = () => {
    if (unsavedChanges3 && window.confirm('You have unsaved changes. Do you want to discard them?')) {
      handleCloseModal3();
      return;
    }
    if (unsavedChanges3) {
      return;
    }
    handleCloseModal3();
  };
  const handleCloseModal3 = () => {
    setLgShow3(false);
    setContent3(initialContent3);
    setUnsavedChanges3(false);
  };
  const handleSave3 = () => {
    setUnsavedChanges3(false);
    setInitialContent3(content3);
    setLgShow3(false);
  };
  const [initialContentheading, setInitialContentheading] = useState(usedata?.proposal_data?.heading ? usedata?.proposal_data?.heading : `
  <h1 style="text-align: center;"><span style="color: rgb(9, 94, 151);"><strong>Enter Speaker or Company</strong></span></h1>
  <h3>LEADERSHIP<br>WORKSHOP<br>PROPOSAL</h3>
  <p>&nbsp;</p>
  <h2 class="APROPOSALTO" style="text-align: left;" align="left"><span style="color: rgb(0, 0, 0); font-size: 18pt; font-family: arial, helvetica, sans-serif;"><strong style="mso-bidi-font-weight: normal;"><span lang="EN-US" style="line-height: 120%;">Prepared for:</span></strong></span></h2>
  <h4 class="APROPOSALTO" style="text-align: left; line-height: 1;" align="left"><span style="font-family: arial, helvetica, sans-serif;"><strong><span style="color: rgb(0, 0, 0); font-size: 14pt;"><span lang="EN-US">Insert Point of Contact</span></span></strong></span></h4>
  <h4 class="APROPOSALTO" style="text-align: left; line-height: 1;" align="left"><span style="font-family: arial, helvetica, sans-serif;"><strong><span style="color: rgb(0, 0, 0); font-size: 14pt;"><span lang="EN-US">Insert Point Client Business Name</span></span></strong></span></h4>
  <h4 class="MonthDayYear01" style="text-align: left; line-height: 1;" align="left"><span style="font-family: arial, helvetica, sans-serif;"><strong><span style="color: rgb(0, 0, 0); font-size: 14pt;"><span lang="EN-US">00 / 00 / 0000</span></span></strong></span></h4>
  <p class="MonthDayYear01" style="text-align: right;" align="left">&nbsp;</p>
  <h2 class="APROPOSALTO" style="text-align: right;" align="left"><span style="color: rgb(0, 0, 0); font-size: 18pt; font-family: arial, helvetica, sans-serif;"><strong style="mso-bidi-font-weight: normal;"><span lang="EN-US" style="line-height: 120%;">Prepared by:</span></strong></span></h2>
  <h4 class="APROPOSALTO" style="text-align:right; line-height: 1;" align="left"><span style="font-family: arial, helvetica, sans-serif;"><strong><span style="color: rgb(0, 0, 0); font-size: 14pt;"><span lang="EN-US">Insert Point of Contact</span></span></strong></span></h4>
  <h4 class="APROPOSALTO" style="text-align:right; line-height: 1;" align="left"><span style="font-family: arial, helvetica, sans-serif;"><strong><span style="color: rgb(0, 0, 0); font-size: 14pt;"><span lang="EN-US">Insert Point Client Business Name</span></span></strong></span></h4>
  <h4 class="MonthDayYear01" style="text-align:right; line-height: 1;" align="left"><span style="font-family: arial, helvetica, sans-serif;"><strong><span style="color: rgb(0, 0, 0); font-size: 18pt;"><span lang="EN-US">CERTIFIED COACH, SPEAKER AND TRAINER</span></span></strong></span></h4>
  <p class="MonthDayYear01" style="text-align: right;" align="left">&nbsp;</p>`)
  const [contentheading, setContentheading] = useState(initialContentheading);
  const [lgShowheading, setLgShowheading] = useState(false);
  const [unsavedChangesheading, setUnsavedChangesheading] = useState(false);
  const handleCloseheading = () => {
    if (unsavedChangesheading && window.confirm('You have unsaved changes. Do you want to discard them?')) {
      handleCloseModalheading();
      return;
    }
    if (unsavedChangesheading) {
      return;
    }
    handleCloseModalheading();
  };
  const handleCloseModalheading = () => {
    setLgShowheading(false);
    setContentheading(initialContentheading);
    setUnsavedChangesheading(false);
  };
  const handleSaveheading = () => {
    setUnsavedChangesheading(false);
    setInitialContentheading(contentheading);
    setLgShowheading(false);
  };
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
  const isToken = localStorage.getItem("UserLoginTokenApt")

  useEffect(() => {
    ProposalViewApi(id,isToken).then((res) => {
    }).catch((e) => {
    })
  })
  const calculatorvalue = JSON.parse(localStorage.getItem('Calculation')) || [];
  // const removeCalculation = () => {
  //   localStorage.removeItem('Calculation')
  //   setValues()
  // }
  const [showBh, setShowBh] = useState(false);
  const handleCloseBH = () => {
    setShowBh(false);
  };
  // const handleSaveChanges = () => {
  //   // Logic to save changes
  //   // setChangesSaved(true);
  //   setShowBh(false); 
  // };
  const [showDraft, setShowDraft] = useState(false);
  const [oldValues, setOldValues] = useState(false);
  // const handleCloseBH = () => setShowBh(false);
  const handleShowBH = () => setShowBh(true);
  const showoldvalues = () => {
    setOldValues(true)
  }
  const handleCloseDraft = () => setShowDraft(false);
  const handleShowDraft = () => setShowDraft(true);
  let slug = new URLSearchParams(location?.search).get("s");
  const [draftstatus, setDraftStatus] = useState("");
  const handleSaveAsDraftWithStatus = async (status) => {
    let draftName = ''; // Declare draftName variable outside of the if block
    setDraftStatus(status); // Set draftstatus according to the button clicked
    if (status === "saveas") {
      draftName = prompt("Please enter your Draft Name", "sampleDraft");
      if (!draftName) return; // If user cancels or dismisses the prompt
    }
    const updatedata = {
      "draft_name": status !== "saveas" ? usedata.draft_name : draftName,
      "calculator_name": calculatorvalue.heading,
      "proposal_name": "Leadership Agreement",
      "proposal_data": {
        "heading": initialContentheading,
        "section_1": initialContent1,
        "section_2": initialContent2,
        "section_3": initialContent3
      },
      "calculator_data": calculatorvalue?.heading ? calculatorvalue : usedata?.calculator_data
    };
    if (status !== "saveas") {
      // console.log(usedata, "usedata in newww")
      UpdateDraft(usedata?.id, updatedata,isToken)
        .then((res) => {
          // console.log(res, "update end fdraft")
          localStorage.removeItem("Calculation");
          navigate("/draft");
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } else {
      DraftCalculationApi(updatedata,isToken)
        .then((res) => {
          console.log(res)
          localStorage.removeItem("Calculation");
          navigate("/draft");
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };
  const handleRemoveCalculation = async () => {
    if (calculatorvalue?.heading) {
      localStorage.removeItem("Calculation");
      window.location.reload();
    }
    else {
      const updatedata = {
        "draft_name": usedata?.draft_name,
        "calculator_name": null,
        "proposal_name": "Leadership Agreement",
        "proposal_data": {
          "heading": initialContentheading,
          "section_1": initialContent1,
          "section_2": initialContent2,
          "section_3": initialContent3
        },
        "calculator_data": null
      };
      UpdateDraft(usedata?.id, updatedata,isToken)
        .then((res) => {
          // console.log(res, "remove calculation")
          setUseData(res?.data?.data)
          // localStorage.removeItem("Calculation");
          // navigate("/draft");
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }
  // console.log(typeof (usedata?.calculator_data), "usedata")
  return (<>
    {!isToken && isToken == null ? <Signin
    /> :
      <>
        <div className='leader'>
          <div className='leader_button'>
            <Button variant="secondary" className="button_color" onClick={() => handleSaveAsDraftWithStatus("saveas")}>
              {slug ? "Create New Draft" : "Create Draft"}
            </Button>&nbsp;
            {slug &&
              <Button variant="secondary" className="button_color" onClick={() => handleSaveAsDraftWithStatus("save")}>
                Update Draft
              </Button>
            }
            &nbsp;
            <Button variant="secondary" className="button_color" onClick={handlePrint}>
              Generate PDF
            </Button>
          </div>
          <br />
          <div ref={contentRef} className="LeadershipProposal  printable-content">
            <div className='textimage'>
              <div className="page">
                <div className='section1button'>
                  <Button onClick={() => setLgShowheading(true)} className="button_theme">Edit Banner Section</Button>
                </div>
                <div dangerouslySetInnerHTML={{ __html: contentheading }} />
                <Modal
                  size="lg"
                  show={lgShowheading}
                  onHide={handleCloseheading}
                  aria-labelledby="example-modal-sizes-title-lg"
                  centered
                >
                  <Modal.Body>
                    <Editor
                      apiKey='f8i59q6p88hcyvaqhicwhyjs2cqwzr8elruwyxphppvzc5yd'
                      value={contentheading}
                      init={{
                        plugins: 'table  ',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: 'Author name',
                        mergetags_list: [
                          { value: 'First.Name', title: 'First Name' },
                          { value: 'Email', title: 'Email' },
                        ],
                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                      }}
                      onClick={() => setUnsavedChangesheading(true)}
                      onEditorChange={(newContent, editor) => {
                        setContentheading(newContent);
                      }}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalheading}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveheading}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className="page">
              <div className='section1button'>
                <Button onClick={() => setLgShow(true)} className="button_theme">Edit this Section</Button>
              </div>
              <div dangerouslySetInnerHTML={{ __html: content1 }} />
              <Modal
                size="lg"
                show={lgShow}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
              >
                <Modal.Body>
                  <Editor
                    apiKey='f8i59q6p88hcyvaqhicwhyjs2cqwzr8elruwyxphppvzc5yd'
                    value={content1}
                    init={{
                      plugins: 'table  ',
                      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                      tinycomments_mode: 'embedded',
                      tinycomments_author: 'Author name',
                      mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                      ],
                      ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    }}
                    onClick={() => setUnsavedChanges(true)}
                    onEditorChange={(newContent, editor) => {
                      setContent1(newContent);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSave}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="page">
              <div className='section1button'>
                <Button onClick={() => setLgShow2(true)} className="button_theme">Edit this Section</Button>
              </div>
              <div dangerouslySetInnerHTML={{ __html: content2 }} />
              <Modal
                size="lg"
                show={lgShow2}
                onHide={handleClose2}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
              >
                <Modal.Body>
                  <Editor
                    apiKey='f8i59q6p88hcyvaqhicwhyjs2cqwzr8elruwyxphppvzc5yd'
                    value={content2}
                    init={{
                      plugins: 'table  ',
                      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                      tinycomments_mode: 'embedded',
                      tinycomments_author: 'Author name',
                      mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                      ],
                      ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    }}
                    onClick={() => setUnsavedChanges2(true)}
                    onEditorChange={(newContent, editor) => {
                      setContent2(newContent);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal2}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSave2}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="page">
              <div className='section1button'>
                <Button onClick={() => setLgShow3(true)} className="button_theme">Edit this Section</Button>
              </div>
              <div dangerouslySetInnerHTML={{ __html: content3 }} />
              <Modal
                size="lg"
                show={lgShow3}
                onHide={handleClose3}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
              >
                <Modal.Body>
                  <Editor
                    apiKey='f8i59q6p88hcyvaqhicwhyjs2cqwzr8elruwyxphppvzc5yd'
                    value={content3}
                    init={{
                      plugins: 'table  ',
                      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                      tinycomments_mode: 'embedded',
                      tinycomments_author: 'Author name',
                      mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                      ],
                      ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    }}
                    onClick={() => setUnsavedChanges3(true)}
                    onEditorChange={(newContent, editor) => {
                      setContent3(newContent);
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal3}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSave3}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className='Calculator_data_include_for_propodal'>
              <div className='Add_Calculation'>
                <Button className="button_theme "
                  onClick={() => { handleShowBH() }}
                >
                  {(slug && usedata?.calculator_name !== null) || calculatorvalue?.heading ? "Change Calculation" : "Add Calculation"
                  }
                </Button>
                {(slug && usedata?.calculator_name !== null) || calculatorvalue?.heading
                  ?
                  <Button className="button_theme "
                    onClick={() => { handleRemoveCalculation() }}
                  >
                    Remove
                  </Button> : null}
                <>
                  <Modal
                    show={showBh} onHide={handleCloseBH} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <AdvancedPrice handleCloseBH={handleCloseBH} />
                    </Modal.Body>
                  </Modal>
                </>
              </div>
            </div>
            {/************************************* softskill */}
            {calculatorvalue && calculatorvalue?.heading === "SoftSkillPricing" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>Soft Skill Pricing Model</h3>
                  <div className='Calculator_container'>
                    {calculatorvalue?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{calculatorvalue?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>AUP:
                      <div className='Col_12'>
                        <div className='blank_input'>${calculatorvalue?.Custom_Aup
                        }</div>
                      </div>
                    </div>
                  </div> */}
                    {calculatorvalue?.Travel_Cost &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Travel Cost :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Travel_Cost}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Per_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session)  :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Per_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Annual_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Fee :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Annual_No_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {calculatorvalue?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{calculatorvalue?.Discount_Value}%
                          </div>
                        </div>
                      </div>
                    }
                    {calculatorvalue?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Discount_Amount}
                          </div>
                        </div>
                      </div>
                    }
                    {calculatorvalue?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session) After Discount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.After_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {calculatorvalue?.Annual_After_Discoun &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Workbook Print Cost (Fedex) $19 :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Workbook_Print_Cost}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Print as a % of one time cost :
                      <div className='Col_12 blank_input'>{calculatorvalue?.One_Time_Cost}%
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_CPI_3}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {(!calculatorvalue && usedata && usedata?.calculator_data?.heading === "SoftSkillPricing") &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>Soft Skill Pricing Model</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>AUP:
                      <div className='Col_12'>
                        <div className='blank_input'>${usedata?.calculator_data?.Custom_Aup
                        }</div>
                      </div>
                    </div>
                  </div> */}
                    {usedata?.calculator_data?.Travel_Cost &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Travel Cost :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Travel_Cost}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Per_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session)  :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Per_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Annual_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Fee :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_No_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Value}%
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Discount_Amount}
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session) After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.Annual_After_Discoun &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Workbook Print Cost (Fedex) $19 :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Workbook_Print_Cost}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Print as a % of one time cost :
                      <div className='Col_12 blank_input'>{usedata?.calculator_data?.One_Time_Cost}%
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_3}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {!oldValues && calculatorvalue && usedata?.calculator_data?.heading === "SoftSkillPricing" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>Soft Skill Pricing Model</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>AUP:
                      <div className='Col_12'>
                        <div className='blank_input'>${usedata?.calculator_data?.Custom_Aup
                        }</div>
                      </div>
                    </div>
                  </div> */}
                    {usedata?.calculator_data?.Travel_Cost &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Travel Cost :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Travel_Cost}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Per_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session)  :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Per_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Annual_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Fee  :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_No_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Value}%
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Discount_Amount}
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session) After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {usedata?.calculator_data?.Annual_After_Discoun &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Workbook Print Cost (Fedex) $19 :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Workbook_Print_Cost}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Print as a % of one time cost :
                      <div className='Col_12 blank_input'>{usedata?.calculator_data?.One_Time_Cost}%
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_3}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {/**************************************OneTime */}
            {(calculatorvalue && calculatorvalue?.heading === "OneTime") &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>One Time All Day Workshop</h3>
                  <div className='Calculator_container'>
                    {calculatorvalue?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{calculatorvalue?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>Hour Rate:
                      <div className='Col_12'>
                        <div className='blank_input'>${calculatorvalue?.CustomPerHour}</div>
                      </div>
                    </div>
                  </div> */}
                    {calculatorvalue?.Travel_Cost &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Travel Cost :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Travel_Cost}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Per_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session)  :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Per_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Annual_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Fee :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Annual_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{calculatorvalue?.Discount_Value}%
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>$
                            {calculatorvalue?.Discount_Amount}%
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session) After Discount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.After_Discount}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Annual_After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Workbook Print Cost (Fedex) $19 :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Workbook_Print_Cost}
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Print as a % of one time cost :
                      <div className='Col_12 blank_input'>{calculatorvalue?.One_Time_Cost}%
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_CPI_3}
                      </div>
                    </div>
                  </div> <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {(!calculatorvalue && usedata && usedata?.calculator_data?.heading === "OneTime") &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>One Time All Day Workshop</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>Hour Rate:
                      <div className='Col_12'>
                        <div className='blank_input'>${usedata?.calculator_data?.CustomPerHour}</div>
                      </div>
                    </div>
                  </div> */}
                    {usedata?.calculator_data?.Travel_Cost &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Travel Cost :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Travel_Cost}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Per_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session)  :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Per_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Annual_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Fee :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Value}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>$
                            {usedata?.calculator_data?.Discount_Amount}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session) After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Annual_After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Workbook Print Cost (Fedex) $19 :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Workbook_Print_Cost}
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Print as a % of one time cost :
                      <div className='Col_12 blank_input'>{usedata?.calculator_data?.One_Time_Cost}%
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_3}
                      </div>
                    </div>
                  </div> <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {(!oldValues && calculatorvalue && usedata?.calculator_data?.heading === "OneTime") &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>One Time All Day Workshop</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>Hour Rate:
                      <div className='Col_12'>
                        <div className='blank_input'>${usedata?.calculator_data?.CustomPerHour}</div>
                      </div>
                    </div>
                  </div> */}
                    {usedata?.calculator_data?.Travel_Cost &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Travel Cost :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Travel_Cost}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Per_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session)  :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Per_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Annual_No_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Fee  :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_No_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Value}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>$
                            {usedata?.calculator_data?.Discount_Amount}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Fee (Month or Session) After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Annual_After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Workbook Print Cost (Fedex) $19 :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Workbook_Print_Cost}
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Print as a % of one time cost :
                      <div className='Col_12 blank_input'>{usedata?.calculator_data?.One_Time_Cost}%
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_3}
                      </div>
                    </div>
                  </div> <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {/************************************* Assessment Programs */}
            {calculatorvalue && calculatorvalue?.heading === "Assessment Programs" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>Assessment Programs</h3>
                  <div className='Calculator_container'>
                    {calculatorvalue?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{calculatorvalue?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>AUP:
                      <div className='Col_12'>
                        <div className='blank_input'>${calculatorvalue?.Custom_Adu}</div>
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Gross Amount :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Gross_Amount}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Assessment fee :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Assessment_fee}
                      </div>
                    </div>
                  </div> */}
                    {calculatorvalue?.Net_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Net Amount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Net_Amount}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{calculatorvalue?.Discount_Amount}%
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Discount_Amount}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          After Discount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.After_Discount}
                          </div>
                        </div>
                      </div>}
                  </div>
                </Row>
              </div>
            }
            {!calculatorvalue && usedata && usedata?.calculator_data?.heading === "Assessment Programs" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>Assessment Programs</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>AUP:
                      <div className='Col_12'>
                        <div className='blank_input'>${usedata?.calculator_data?.Custom_Adu}</div>
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Gross Amount :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Gross_Amount}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Assessment fee :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Assessment_fee}
                      </div>
                    </div>
                  </div> */}
                    {usedata?.calculator_data?.Net_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Net Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Net_Amount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Amount}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Discount_Amount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                          </div>
                        </div>
                      </div>}
                  </div>
                </Row>
              </div>
            }
            {!oldValues && calculatorvalue && usedata?.calculator_data?.heading === "Assessment Programs" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>Assessment Programs</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>AUP:
                      <div className='Col_12'>
                        <div className='blank_input'>${usedata?.calculator_data?.Custom_Adu}</div>
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Gross Amount :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Gross_Amount}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Assessment fee :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Assessment_fee}
                      </div>
                    </div>
                  </div> */}
                    {usedata?.calculator_data?.Net_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Net Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Net_Amount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Amount}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Discount_Amount}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                          </div>
                        </div>
                      </div>}
                  </div>
                </Row>
              </div>
            }
            {/************************************* LMS */}
            {calculatorvalue && calculatorvalue?.heading === "LMS" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>LMS</h3>
                  <div className='Calculator_container'>
                    {calculatorvalue?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{calculatorvalue?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Custom_Adu &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>AUP:
                          <div className='Col_12'>
                            <div className='blank_input'>${calculatorvalue?.Custom_Adu}</div>
                          </div>
                        </div>
                      </div>}{calculatorvalue?.Monthly_Revenue &&
                        <div className='Row_1'>
                          <div className='Col_1 blank_input'>
                            Monthly Revenue :
                            <div className='Col_12 blank_input'>${calculatorvalue?.Monthly_Revenue}
                            </div>
                          </div>
                        </div>}
                    {calculatorvalue?.Annual_Revenue &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Revenue:
                          <div className='Col_12 blank_input'>${calculatorvalue?.Annual_Revenue}
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{calculatorvalue?.Discount_Value}%
                          </div>
                        </div>
                      </div>}
                    {calculatorvalue?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Discount_Amount}
                          </div>
                        </div>
                      </div>}{calculatorvalue?.After_Discount &&
                        <div className='Row_1'>
                          <div className='Col_1 blank_input'>
                            Fee (Month or Session) After Discount :
                            <div className='Col_12 blank_input'>${calculatorvalue?.After_Discount}
                            </div>
                          </div>
                        </div>}
                    {calculatorvalue?.Annual_After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${calculatorvalue?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Monthly_Cost_from_CTM:
                      <div className='Col_12 blank_input'>${calculatorvalue?.Monthly_Cost_from_CTM}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual Monthly Cost from CTM :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_Monthly_Cost_from_CTM}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      CTM Cost Percentage :
                      <div className='Col_12 blank_input'>{calculatorvalue?.CTM_Cost_Percentage}%
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_CPI_3
                      }
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${calculatorvalue?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {!calculatorvalue && usedata && usedata?.calculator_data?.heading === "LMS" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>LMS</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Custom_Adu &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>AUP:
                          <div className='Col_12'>
                            <div className='blank_input'>${usedata?.calculator_data?.Custom_Adu}</div>
                          </div>
                        </div>
                      </div>}{usedata?.calculator_data?.Monthly_Revenue &&
                        <div className='Row_1'>
                          <div className='Col_1 blank_input'>
                            Monthly Revenue :
                            <div className='Col_12 blank_input'>${usedata?.calculator_data?.Monthly_Revenue}
                            </div>
                          </div>
                        </div>}
                    {usedata?.calculator_data?.Annual_Revenue &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Revenue:
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_Revenue}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Value}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Discount_Amount}
                          </div>
                        </div>
                      </div>}{usedata?.calculator_data?.After_Discount &&
                        <div className='Row_1'>
                          <div className='Col_1 blank_input'>
                            Fee (Month or Session) After Discount :
                            <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                            </div>
                          </div>
                        </div>}
                    {usedata?.calculator_data?.Annual_After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Monthly_Cost_from_CTM:
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Monthly_Cost_from_CTM}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual Monthly Cost from CTM :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_Monthly_Cost_from_CTM}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      CTM Cost Percentage :
                      <div className='Col_12 blank_input'>{usedata?.calculator_data?.CTM_Cost_Percentage}%
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_3
                      }
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
            {!oldValues && calculatorvalue && usedata?.calculator_data?.heading === "LMS" &&
              <div className='calculator_main_container'>
                <Row className='cal_main'>
                  <h3 className='heading'>LMS</h3>
                  <div className='Calculator_container'>
                    {usedata?.calculator_data?.Custom_Participants &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>Participants:
                          <div className='Col_12'>
                            <div className='blank_input'>{usedata?.calculator_data?.Custom_Participants}</div>
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Custom_Adu &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>AUP:
                          <div className='Col_12'>
                            <div className='blank_input'>${usedata?.calculator_data?.Custom_Adu}</div>
                          </div>
                        </div>
                      </div>}{usedata?.calculator_data?.Monthly_Revenue &&
                        <div className='Row_1'>
                          <div className='Col_1 blank_input'>
                            Monthly Revenue :
                            <div className='Col_12 blank_input'>${usedata?.calculator_data?.Monthly_Revenue}
                            </div>
                          </div>
                        </div>}
                    {usedata?.calculator_data?.Annual_Revenue &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual Revenue:
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_Revenue}
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Value &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Select Discount :
                          <div className='Col_12 blank_input'>{usedata?.calculator_data?.Discount_Value}%
                          </div>
                        </div>
                      </div>}
                    {usedata?.calculator_data?.Discount_Amount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Discount Amount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Discount_Amount}
                          </div>
                        </div>
                      </div>}{usedata?.calculator_data?.After_Discount &&
                        <div className='Row_1'>
                          <div className='Col_1 blank_input'>
                            Fee (Month or Session) After Discount :
                            <div className='Col_12 blank_input'>${usedata?.calculator_data?.After_Discount}
                            </div>
                          </div>
                        </div>}
                    {usedata?.calculator_data?.Annual_After_Discount &&
                      <div className='Row_1'>
                        <div className='Col_1 blank_input'>
                          Annual After Discount :
                          <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_After_Discount}
                          </div>
                        </div>
                      </div>}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Monthly_Cost_from_CTM:
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Monthly_Cost_from_CTM}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual Monthly Cost from CTM :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_Monthly_Cost_from_CTM}
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      CTM Cost Percentage :
                      <div className='Col_12 blank_input'>{usedata?.calculator_data?.CTM_Cost_Percentage}%
                      </div>
                    </div>
                  </div> */}
                    {/* <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 3% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_3
                      }
                      </div>
                    </div>
                  </div>
                  <div className='Row_1'>
                    <div className='Col_1 blank_input'>
                      Annual CPI 5% :
                      <div className='Col_12 blank_input'>${usedata?.calculator_data?.Annual_CPI_5}
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Row>
              </div>
            }
          </div>
          <br />
        </div>
      </>
    }
  </>
  )
}
export default LeadershipProposal
