import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { UserLoginAPI } from "../../Constants/Api/Api";
import { Link, useNavigate } from "react-router-dom";
import DescriptionAlerts from "../../Constants/alert/alert";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Logo from "../../assets/apt.png";
import "./signin.scss";
import { Button } from "react-bootstrap";

const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    text: "",
    icon: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const checkEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    setAlert(false);
    if (!checkEmailFormat(email)) {
      setIsValidEmail(false);
      return;
    }
    setIsValidEmail(true);
    UserLoginAPI(email, password)
      .then((res) => {
        if (res?.status === 200 || res?.data?.status === 200) {
          setTimeout(() => {
            const token = res?.data?.data?.tokens?.access?.token;
            if (
              res?.data?.data?.user_status === "DEACTIVATE" &&
              res?.data?.data?.is_trial_period === false
            ) {
              localStorage.setItem("UserStatus", res?.data?.data?.user_status);
              localStorage.setItem("is_active", res?.data?.data?.is_active);
              setAlert(true);
              setAlertConfig({
                text: "Your trial period is over. Please subscribe to log in again.",
                icon: "error",
              });
            } else if (
              res?.data?.data?.user_status === "DEACTIVATE" &&
              res?.data?.data?.is_subscribed === false
            ) {
              localStorage.setItem("UserStatus", res?.data?.data?.user_status);
              localStorage.setItem("is_active", res?.data?.data?.is_active);
              setAlert(true);
              setAlertConfig({
                text: "Subscription expired. Please subscribe to log in again.",
                icon: "error",
              });
            } else {
              localStorage.setItem("UserLoginTokenApt", token);
              localStorage.setItem("UserStatus", res?.data?.data?.user_status);
              localStorage.setItem("is_active", res?.data?.data?.is_active);
              setAlert(true);
              setAlertConfig({
                text: "Congratulations! You have successfully logged in.",
                icon: "success",
              });
              navigate("/");
            }
          }, 4000);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401 || error?.response?.data?.status === 400) {
          setAlert(true);
          setAlertConfig({
            text: error.response.data.message,
            icon: "error",
          });
        }
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      {alert && <DescriptionAlerts text={alertConfig.text} icon={alertConfig.icon} />}
      <div className="Signin">
        <div className="Main">
          <div className="Left">
            <img src={Logo} width="100%" alt="logo" />
          </div>
          <div className="Right">
            <div className="form_inner">
              <h1>Sign in</h1>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsValidEmail(checkEmailFormat(e.target.value));
                    }}
                    isInvalid={!isValidEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <p onClick={() => navigate("/forgotpassword")} style={{ cursor: "pointer" }}>
                  Forgot password?
                </p>

                <Button className="button_theme" onClick={handleSubmit}>
                  Sign in
                </Button>

                <p className="account_text">
                  <Link to="https://www.sendowl.com/s/digital/automated-pricing-tool-by-lafleur-leadership-books/" className="link_text">
                    Subscribe to create an account
                  </Link>
                  <br />
                  <span>
                    <Link to="/send-email-verification" className="link_text">
                      Register for free trial
                    </Link>
                  </span>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;