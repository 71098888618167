import React from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import "./SendOtp.scss";
import Image from "../../assets/Automated_Pricing_Calc.jpg";
import { SendOTPAPI } from '../../Constants/Api/Api';

const SendOtpComponent = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const useremail = formData.get("useremail");

    try {
      const res = await SendOTPAPI(useremail);
      navigate("/verifyotp", { state: { email: useremail } });
      alert(res?.data?.message); // Use Bootstrap alert or other notifications
    } catch (error) {
      alert(error?.response?.data?.message || "Error sending OTP");
    }
  };

  return (
    <Container className="VerifyOtp main-container">
      <div className="Main">
        <div className="Left">
          <img src={Image} width="100%" alt="logo" />
        </div>
        <div className="Right">
          <div className="form_inner">
            <h1>Sign up</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formUserEmail">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  name="useremail"
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>
              <div className="d-grid">
                <Button type="submit" variant="primary" className="button_theme">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
          <p className="bottom_text">
            Already have an account? <Link to="/signin">Sign in</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SendOtpComponent;
