import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from "../../assets/apt.png"
import "./NavSection.scss"
import { useLocation, useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import SubNav from './SubNav';
function NavSection() {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleClosesidebar = () => setShow(false);
  const handleShowsidebar = () => setShow(true);
  const { pathname } = useLocation()
  const storedValue = localStorage.getItem("UserLoginTokenApt");
  const [offcanvasWidth, setOffcanvasWidth] = useState('');
  useEffect(() => {
    // Check if window is defined (client-side) before accessing it
    if (typeof window !== 'undefined') {
      const width = window.innerWidth >= 1200 ? '50%' : '80%';
      setOffcanvasWidth(width);
    }
    
  }, []);
  return (
    <Navbar className="NavbarSection" sticky="top" >
      <Container>
        <Navbar.Brand
          onClick={() => {
            navigate("/")
          }}
          
        >
          {/* <h4 style={{ cursor: "pointer", color: "#C8232C", fontWeight: "bold" }} ></h4> */}
          <img src={Logo} className='logo' alt='logo' />
        </Navbar.Brand>
        {pathname === "/forgotpassword" ?
          <Button style={{
            borderRadius: "10px",
            background: "#C8232C", border: "none"
          }}
            onClick={() => { navigate("/signin"); handleClosesidebar() }}
          >Login</Button>
          :
          pathname !== "/" && !storedValue && storedValue === null ? null :
            <>
              <div className="desktop_navbar">
                <SubNav handleClosesidebar={handleClosesidebar} />
              </div>
              <RxHamburgerMenu size={80} onClick={!show ? handleShowsidebar : handleClosesidebar} className='mobile_navbar' />
              <>
                <Offcanvas show={show} onHide={handleClosesidebar} placement='end'
                  style={{ width: offcanvasWidth }}
                >
                  <Offcanvas.Body>
                    <div className='SubNav' style={{ margin: "100px 0px" }}>
                      <SubNav handleClosesidebar={handleClosesidebar} />
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </>
            </>
        }
      </Container>
    </Navbar>
  );
}
export default NavSection;